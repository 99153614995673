<template>
    <div class="view-user">
        <el-form
            ref="userForm"
            :inline="true"
            :model="form"
            class="form-inline"
            label-width="80px"
            size="mini"
            label-position="left"
        >
            <el-row>
                <el-form-item label="账号" prop="username">
                    <el-input
                        v-model="form.username"
                        placeholder="账号"
                    ></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input
                        v-model="form.email"
                        placeholder="邮箱"
                    ></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="phone">
                    <el-input
                        v-model="form.phone"
                        placeholder="手机号码"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryTable"
                        >查询</el-button
                    >
                    <el-button
                        @click="resetForm"
                        icon="el-icon-refresh-right"
                    ></el-button>
                </el-form-item>
            </el-row>
        </el-form>
        <div class="button-ctrl">
            <el-button
                type="success"
                @click="showUserDialog('add')"
                icon="el-icon-plus"
                size="mini"
                >新增</el-button
            >
        </div>
        <TableList
            class="user-table"
            ref="userTable"
            :query="form"
            :columns="table.columms"
            :tableData.sync="table.tableData"
            :page.sync="table.page"
            :doHandler="table.doHandler"
            fullHeight
            border
        >
            <template slot="action" slot-scope="{ data }">
                <div class="column-scope-action">
                    <el-link
                        type="primary"
                        icon="el-icon-edit"
                        @click="showUserDialog('update', data)"
                        >编辑</el-link
                    >
                    <el-link
                        type="primary"
                        icon="el-icon-connection"
                        @click="showRelRoleDialog(data)"
                        >绑定角色</el-link
                    >
                    <el-link
                        type="info"
                        icon="el-icon-view"
                        @click="showUserDialog('detail', data)"
                        >详情</el-link
                    >
                    <el-link
                        type="danger"
                        icon="el-icon-delete"
                        @click="deleteUser(data)"
                        >删除</el-link
                    >
                </div>
            </template>
        </TableList>
        <UserDialog
            :title="dialog.user.title"
            :visible.sync="dialog.user.visible"
            :type="dialog.user.type"
            :data="dialog.user.data"
            @reload="queryTable"
        />
        <RelRole
            :visible.sync="dialog.relRole.visible"
            :data="dialog.relRole.data"
        />
    </div>
</template>
<script>
import TableList from "@/components/table-list";
import UserDialog from "./userDialog";
import RelRole from "./relRole";
import { getUserPageApi, deleteUserApi } from "@/api/system/user.js";
import columms from "./columns";

export default {
    name: "User",
    components: {
        TableList,
        UserDialog,
        RelRole
    },
    data() {
        return {
            form: {
                username: "",
                email: "",
                phone: ""
            },
            table: {
                columms,
                page: {
                    page: 1,
                    pageSize: 10,
                    total: 0
                },
                doHandler: getUserPageApi,
                tableData: []
            },
            dialog: {
                user: {
                    visible: false,
                    type: "",
                    title: "",
                    data: undefined
                },
                relRole: {
                    visible: false,
                    data: undefined
                }
            }
        };
    },
    methods: {
        queryTable() {
            this.$refs["userTable"].doQuery();
        },
        resetForm() {
            this.$refs["userForm"].resetFields();
        },
        showUserDialog(type, data) {
            this.dialog.user.visible = true;
            this.dialog.user.type = type;
            this.dialog.user.data = data;
            this.dialog.user.title =
                type === "add"
                    ? "新增用户"
                    : type === "update"
                    ? "编辑用户"
                    : "用户详情";
        },
        showRelRoleDialog(data) {
            this.dialog.relRole.visible = true;
            this.dialog.relRole.data = data;
        },
        deleteUser(data) {
            this.$confirm(
                `此操作将永久删除该用户（账号：${data.username}）, 是否继续?`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            )
                .then(() => {
                    this.callDeleteUserApi(data);
                })
                .catch(() => {})
                .finally(() => {});
        },
        async callDeleteUserApi(data) {
            return new Promise((resolve, reject) => {
                deleteUserApi(data)
                    .then(() => {
                        this.$notify({
                            title: "成功",
                            message: "删除成功",
                            type: "success"
                        });
                        resolve();
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    })
                    .finally(() => {
                        this.queryTable();
                    });
            });
        }
    },
    mounted() {
        this.queryTable();
    }
};
</script>
<style lang="scss" scoped>
.view-user {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    .button-ctrl {
        margin-bottom: 10px;
    }
    .user-table {
        flex: 1;
    }
    .column-scope-action {
        > * + * {
            margin-left: 10px;
        }
    }
}
</style>
