export default [
    {
        prop: "username",
        label: "账号",
        minWidth: 150
    },
    {
        prop: "email",
        label: "邮箱",
        minWidth: 255
    },
    {
        prop: "phone",
        label: "手机号码",
        minWidth: 140
    },
    {
        prop: "action",
        label: "操作",
        fixed: "right",
        width: 270
    }
];
